.cropping-modal-container {
  z-index: 999999999;
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(109 144 90 / 30%);
  backdrop-filter: blur(2px);
  border-radius: 5px;
}
.swal2-container {
  z-index: 9999999991 !important;
}

.modal-card {
  width: 1238px;
  height: auto;
  margin: 8.5% auto 0;
  padding: 56px 56px 27px 56px;
  background: white;
  box-shadow: 0px 90px 80px rgba(109, 144, 90, 0.3);
  border-radius: 5px;

  @media (max-width: 575px ) {
    margin-right: 15px;
    margin-left: 15px;
  }
  .centered-icon-container {
    margin-bottom: 16px;
  }

  h2 {
    font-family: 'Montserrat', sans-serif;
    color: $text-primary;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    margin-bottom: 32px;
  }

  .image-container {
    max-height: 388px !important;
    max-width: 1238px !important;
    width: 100%;
    overflow: hidden;
  }

  .modal-actions {
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;

    button {
      font-size: 18px;
      width: 100%;
      max-width: 156px;
      display: flex;
      align-items: center;
      justify-content: center;


      &:first-of-type {
        margin-right: 16px;
      }

      .loading-icon { display: none; }
    }
  }
}