.Article {
  h1 {
    margin-top: rem-calc(12);
    margin-bottom: rem-calc(32);
    font-size: rem-calc(56);
    font-weight: 700;
    line-height: rem-calc(68);

    @include breakpoint(large) {
      margin-top: rem-calc(12);
      margin-bottom: rem-calc(32);
      font-size: rem-calc(80);
      font-weight: 700;
      line-height: rem-calc(98);
    }
  }

  h2 {
    margin-top: rem-calc(24);
    margin-bottom: rem-calc(24);
  }

  p {
    margin-top: rem-calc(32);
    margin-bottom: rem-calc(32);
  }

  p,
  ul li {
    a {
      color: $green;
      text-decoration: underline;
      @include theme-primary-color;

      &:hover, &:focus {
        text-decoration: none;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  ul {
    margin-top: rem-calc(32);
    margin-bottom: rem-calc(32);
    list-style: none; /* Remove default bullets */
    
    li::before {
      content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: $green; /* Change the color */
      @include theme-primary-color;
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
  }

  &-social {
    padding-top: rem-calc(18);
    padding-bottom: rem-calc(30);

    @include breakpoint(large) {
      padding-top: rem-calc(18);
      padding-bottom: rem-calc(50);
    }

    .Button {
      width: 100%;
      margin-bottom: rem-calc(20);

      @include breakpoint(large) {
        margin-bottom: 0;
      }
    }
  }

  &-author {
    padding-top: rem-calc(40);
    padding-bottom: rem-calc(40);
    border-top: rem-calc(1) solid $gray-lighter;
    border-bottom: rem-calc(1) solid $gray-lighter;

    &-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;

      @include breakpoint(50em) {
        flex-flow: row nowrap;
        justify-content: flex-start;
      }
    }

    &-image {
      width: rem-calc(180);
      height: rem-calc(180);
      margin-right: 0;
      margin-bottom: rem-calc(16);
      border-radius: rem-calc(180);
      background-color: $black;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @include breakpoint(50em) {
        margin-right: rem-calc(40);
        margin-bottom: 0;
      }
    }
    
    &-info {
      flex: 1;
      text-align: center;

      @include breakpoint(50em) {
        text-align: left;
      }

      h3 {
        margin-top: 0;
        margin-bottom: rem-calc(16);
      }

      p {
        margin-top: 0;
        margin-bottom: rem-calc(16);
      }
    }
  }

  &-related {
    padding-top: rem-calc(90);
    padding-bottom: rem-calc(90);
  }

  .rich-content {
    p { margin: 0; }
    blockquote {
      margin-top: 0;
      margin-bottom: 0;
    }
    img { max-width: 100%; }
  }
}