$blueish: #6597A2;
$green: #6D905A;
$green-light: #E6E8E3;
$red: #C90500;
$success: #4BB543;

$white-darker: #F2F2F2;
$gray: rgba(#303C48, 0.5);
$gray-lighter: rgba(0, 55, 66, 0.2);
$black-lighter: #303C48;
$white: #FFFFFF;
$black: #000000;

$bg-light: #F2F2F2;
$bg-blueish: $blueish;
$bg-dark: #262626;
$text-primary: #303C48;
$text-secondary: rgba(#303C48, 0.9);

$theme-color-1: #7B68EE;
$theme-color-2: #FFA12F;
$theme-color-3: #FF5722;
$theme-color-4: #0998D8;
$theme-color-5: #F8306D;
$theme-color-6: #FF00FC;
$theme-color-7: #4169E1;
$theme-color-8: #5F81FF;
$theme-color-9: #08C7E0;
$theme-color-10: #2EA52C;
//$theme-color-11: #757380;
$theme-color-11: #6D905A;
//$theme-color-12: #202020;
$theme-color-12: #6D905A;
$theme-color-13: #757380;
$theme-color-14: #202020;

@mixin theme-primary-background-color {
  @at-root .primary-color-1 & { background-color: $theme-color-1; }
  @at-root .primary-color-2 & { background-color: $theme-color-2; }
  @at-root .primary-color-3 & { background-color: $theme-color-3; }
  @at-root .primary-color-4 & { background-color: $theme-color-4; }
  @at-root .primary-color-5 & { background-color: $theme-color-5; }
  @at-root .primary-color-6 & { background-color: $theme-color-6; }
  @at-root .primary-color-7 & { background-color: $theme-color-7; }
  @at-root .primary-color-8 & { background-color: $theme-color-8; }
  @at-root .primary-color-9 & { background-color: $theme-color-9; }
  @at-root .primary-color-10 & { background-color: $theme-color-10; }
  @at-root .primary-color-11 & { background-color: $theme-color-11; }
  @at-root .primary-color-12 & { background-color: $theme-color-12; }
  @at-root .primary-color-13 & { background-color: $theme-color-13; }
  @at-root .primary-color-14 & { background-color: $theme-color-14; }
}
@mixin theme-primary-background-color-opacity {
  @at-root .primary-color-1 & { background-color: lighten($theme-color-1, 34%); }
  @at-root .primary-color-2 & { background-color: lighten($theme-color-2, 34%); }
  @at-root .primary-color-3 & { background-color: lighten($theme-color-3, 34%); }
  @at-root .primary-color-4 & { background-color: lighten($theme-color-4, 34%); }
  @at-root .primary-color-5 & { background-color: lighten($theme-color-5, 34%); }
  @at-root .primary-color-6 & { background-color: lighten($theme-color-6, 34%); }
  @at-root .primary-color-7 & { background-color: lighten($theme-color-7, 34%); }
  @at-root .primary-color-8 & { background-color: lighten($theme-color-8, 34%); }
  @at-root .primary-color-9 & { background-color: lighten($theme-color-9, 34%); }
  @at-root .primary-color-10 & { background-color: lighten($theme-color-10, 34%); }
  @at-root .primary-color-11 & { background-color: lighten($theme-color-11, 34%); }
  @at-root .primary-color-12 & { background-color: lighten($theme-color-12, 34%); }
  @at-root .primary-color-13 & { background-color: lighten($theme-color-13, 34%); }
  @at-root .primary-color-14 & { background-color: lighten($theme-color-14, 34%); }
}
@mixin theme-primary-background-color-opacity-important {
  @at-root .primary-color-1 & { background-color: lighten($theme-color-1, 34%) !important; }
  @at-root .primary-color-2 & { background-color: lighten($theme-color-2, 34%) !important; }
  @at-root .primary-color-3 & { background-color: lighten($theme-color-3, 34%) !important; }
  @at-root .primary-color-4 & { background-color: lighten($theme-color-4, 34%) !important; }
  @at-root .primary-color-5 & { background-color: lighten($theme-color-5, 34%) !important; }
  @at-root .primary-color-6 & { background-color: lighten($theme-color-6, 34%) !important; }
  @at-root .primary-color-7 & { background-color: lighten($theme-color-7, 34%) !important; }
  @at-root .primary-color-8 & { background-color: lighten($theme-color-8, 34%) !important; }
  @at-root .primary-color-9 & { background-color: lighten($theme-color-9, 34%) !important; }
  @at-root .primary-color-10 & { background-color: lighten($theme-color-10, 34%) !important; }
  @at-root .primary-color-11 & { background-color: lighten($theme-color-11, 34%) !important; }
  @at-root .primary-color-12 & { background-color: lighten($theme-color-12, 34%) !important; }
  @at-root .primary-color-13 & { background-color: lighten($theme-color-13, 34%) !important; }
  @at-root .primary-color-14 & { background-color: lighten($theme-color-14, 34%) !important; }
}
@mixin theme-primary-background-color-opacity-dark {
  @at-root .primary-color-1 & { background-color: lighten($theme-color-1, 30%); }
  @at-root .primary-color-2 & { background-color: lighten($theme-color-2, 30%); }
  @at-root .primary-color-3 & { background-color: lighten($theme-color-3, 30%); }
  @at-root .primary-color-4 & { background-color: lighten($theme-color-4, 30%); }
  @at-root .primary-color-5 & { background-color: lighten($theme-color-5, 30%); }
  @at-root .primary-color-6 & { background-color: lighten($theme-color-6, 30%); }
  @at-root .primary-color-7 & { background-color: lighten($theme-color-7, 30%); }
  @at-root .primary-color-8 & { background-color: lighten($theme-color-8, 30%); }
  @at-root .primary-color-9 & { background-color: lighten($theme-color-9, 30%); }
  @at-root .primary-color-10 & { background-color: lighten($theme-color-10, 30%); }
  @at-root .primary-color-11 & { background-color: lighten($theme-color-11, 30%); }
  @at-root .primary-color-12 & { background-color: lighten($theme-color-12, 30%); }
  @at-root .primary-color-13 & { background-color: lighten($theme-color-13, 30%); }
  @at-root .primary-color-14 & { background-color: lighten($theme-color-14, 30%); }
}
@mixin theme-primary-background-color-root {
  @at-root .primary-color-1#{&} { background-color: $theme-color-1; }
  @at-root .primary-color-2#{&} { background-color: $theme-color-2; }
  @at-root .primary-color-3#{&} { background-color: $theme-color-3; }
  @at-root .primary-color-4#{&} { background-color: $theme-color-4; }
  @at-root .primary-color-5#{&} { background-color: $theme-color-5; }
  @at-root .primary-color-6#{&} { background-color: $theme-color-6; }
  @at-root .primary-color-7#{&} { background-color: $theme-color-7; }
  @at-root .primary-color-8#{&} { background-color: $theme-color-8; }
  @at-root .primary-color-9#{&} { background-color: $theme-color-9; }
  @at-root .primary-color-10#{&} { background-color: $theme-color-10; }
  @at-root .primary-color-11#{&} { background-color: $theme-color-11; }
  @at-root .primary-color-12#{&} { background-color: $theme-color-12; }
  @at-root .primary-color-13#{&} { background-color: $theme-color-13; }
  @at-root .primary-color-14#{&} { background-color: $theme-color-14; }
}
@mixin theme-primary-border-color {
  @at-root .primary-color-1 & { border-color: $theme-color-1; }
  @at-root .primary-color-2 & { border-color: $theme-color-2; }
  @at-root .primary-color-3 & { border-color: $theme-color-3; }
  @at-root .primary-color-4 & { border-color: $theme-color-4; }
  @at-root .primary-color-5 & { border-color: $theme-color-5; }
  @at-root .primary-color-6 & { border-color: $theme-color-6; }
  @at-root .primary-color-7 & { border-color: $theme-color-7; }
  @at-root .primary-color-8 & { border-color: $theme-color-8; }
  @at-root .primary-color-9 & { border-color: $theme-color-9; }
  @at-root .primary-color-10 & { border-color: $theme-color-10; }
  @at-root .primary-color-11 & { border-color: $theme-color-11; }
  @at-root .primary-color-12 & { border-color: $theme-color-12; }
  @at-root .primary-color-13 & { border-color: $theme-color-13; }
  @at-root .primary-color-14 & { border-color: $theme-color-14; }
}
@mixin theme-primary-color {
  @at-root .primary-color-1 & { color: $theme-color-1; }
  @at-root .primary-color-2 & { color: $theme-color-2; }
  @at-root .primary-color-3 & { color: $theme-color-3; }
  @at-root .primary-color-4 & { color: $theme-color-4; }
  @at-root .primary-color-5 & { color: $theme-color-5; }
  @at-root .primary-color-6 & { color: $theme-color-6; }
  @at-root .primary-color-7 & { color: $theme-color-7; }
  @at-root .primary-color-8 & { color: $theme-color-8; }
  @at-root .primary-color-9 & { color: $theme-color-9; }
  @at-root .primary-color-10 & { color: $theme-color-10; }
  @at-root .primary-color-11 & { color: $theme-color-11; }
  @at-root .primary-color-12 & { color: $theme-color-12; }
  @at-root .primary-color-13 & { color: $theme-color-13; }
  @at-root .primary-color-14 & { color: $theme-color-14; }
}
@mixin theme-primary-color-root {
  @at-root .primary-color-1#{&} { color: $theme-color-1; }
  @at-root .primary-color-2#{&} { color: $theme-color-2; }
  @at-root .primary-color-3#{&} { color: $theme-color-3; }
  @at-root .primary-color-4#{&} { color: $theme-color-4; }
  @at-root .primary-color-5#{&} { color: $theme-color-5; }
  @at-root .primary-color-6#{&} { color: $theme-color-6; }
  @at-root .primary-color-7#{&} { color: $theme-color-7; }
  @at-root .primary-color-8#{&} { color: $theme-color-8; }
  @at-root .primary-color-9#{&} { color: $theme-color-9; }
  @at-root .primary-color-10#{&} { color: $theme-color-10; }
  @at-root .primary-color-11#{&} { color: $theme-color-11; }
  @at-root .primary-color-12#{&} { color: $theme-color-12; }
  @at-root .primary-color-13#{&} { color: $theme-color-13; }
  @at-root .primary-color-14#{&} { color: $theme-color-14; }
}
@mixin theme-primary-fill {
  @at-root .primary-color-1 & { fill: $theme-color-1; }
  @at-root .primary-color-2 & { fill: $theme-color-2; }
  @at-root .primary-color-3 & { fill: $theme-color-3; }
  @at-root .primary-color-4 & { fill: $theme-color-4; }
  @at-root .primary-color-5 & { fill: $theme-color-5; }
  @at-root .primary-color-6 & { fill: $theme-color-6; }
  @at-root .primary-color-7 & { fill: $theme-color-7; }
  @at-root .primary-color-8 & { fill: $theme-color-8; }
  @at-root .primary-color-9 & { fill: $theme-color-9; }
  @at-root .primary-color-10 & { fill: $theme-color-10; }
  @at-root .primary-color-11 & { fill: $theme-color-11; }
  @at-root .primary-color-12 & { fill: $theme-color-12; }
  @at-root .primary-color-13 & { fill: $theme-color-13; }
  @at-root .primary-color-14 & { fill: $theme-color-14; }
}
@mixin theme-primary-fill-root {
  @at-root .primary-color-1#{&} { fill: $theme-color-1; }
  @at-root .primary-color-2#{&} { fill: $theme-color-2; }
  @at-root .primary-color-3#{&} { fill: $theme-color-3; }
  @at-root .primary-color-4#{&} { fill: $theme-color-4; }
  @at-root .primary-color-5#{&} { fill: $theme-color-5; }
  @at-root .primary-color-6#{&} { fill: $theme-color-6; }
  @at-root .primary-color-7#{&} { fill: $theme-color-7; }
  @at-root .primary-color-8#{&} { fill: $theme-color-8; }
  @at-root .primary-color-9#{&} { fill: $theme-color-9; }
  @at-root .primary-color-10#{&} { fill: $theme-color-10; }
  @at-root .primary-color-11#{&} { fill: $theme-color-11; }
  @at-root .primary-color-12#{&} { fill: $theme-color-12; }
  @at-root .primary-color-13#{&} { fill: $theme-color-13; }
  @at-root .primary-color-14#{&} { fill: $theme-color-14; }
}



@mixin theme-secondary-background-color {
  @at-root .secondary-color-1 & { background-color: $theme-color-1; }
  @at-root .secondary-color-2 & { background-color: $theme-color-2; }
  @at-root .secondary-color-3 & { background-color: $theme-color-3; }
  @at-root .secondary-color-4 & { background-color: $theme-color-4; }
  @at-root .secondary-color-5 & { background-color: $theme-color-5; }
  @at-root .secondary-color-6 & { background-color: $theme-color-6; }
  @at-root .secondary-color-7 & { background-color: $theme-color-7; }
  @at-root .secondary-color-8 & { background-color: $theme-color-8; }
  @at-root .secondary-color-9 & { background-color: $theme-color-9; }
  @at-root .secondary-color-10 & { background-color: $theme-color-10; }
  @at-root .secondary-color-11 & { background-color: $theme-color-11; }
  @at-root .secondary-color-12 & { background-color: $theme-color-12; }
  @at-root .secondary-color-13 & { background-color: $theme-color-13; }
  @at-root .secondary-color-14 & { background-color: $theme-color-14; }
}
@mixin theme-secondary-border-color {
  @at-root .secondary-color-1 & { border-color: $theme-color-1; }
  @at-root .secondary-color-2 & { border-color: $theme-color-2; }
  @at-root .secondary-color-3 & { border-color: $theme-color-3; }
  @at-root .secondary-color-4 & { border-color: $theme-color-4; }
  @at-root .secondary-color-5 & { border-color: $theme-color-5; }
  @at-root .secondary-color-6 & { border-color: $theme-color-6; }
  @at-root .secondary-color-7 & { border-color: $theme-color-7; }
  @at-root .secondary-color-8 & { border-color: $theme-color-8; }
  @at-root .secondary-color-9 & { border-color: $theme-color-9; }
  @at-root .secondary-color-10 & { border-color: $theme-color-10; }
  @at-root .secondary-color-11 & { border-color: $theme-color-11; }
  @at-root .secondary-color-12 & { border-color: $theme-color-12; }
  @at-root .secondary-color-13 & { border-color: $theme-color-13; }
  @at-root .secondary-color-14 & { border-color: $theme-color-14; }
}
@mixin theme-secondary-color {
  @at-root .secondary-color-1 & { color: $theme-color-1; }
  @at-root .secondary-color-2 & { color: $theme-color-2; }
  @at-root .secondary-color-3 & { color: $theme-color-3; }
  @at-root .secondary-color-4 & { color: $theme-color-4; }
  @at-root .secondary-color-5 & { color: $theme-color-5; }
  @at-root .secondary-color-6 & { color: $theme-color-6; }
  @at-root .secondary-color-7 & { color: $theme-color-7; }
  @at-root .secondary-color-8 & { color: $theme-color-8; }
  @at-root .secondary-color-9 & { color: $theme-color-9; }
  @at-root .secondary-color-10 & { color: $theme-color-10; }
  @at-root .secondary-color-11 & { color: $theme-color-11; }
  @at-root .secondary-color-12 & { color: $theme-color-12; }
  @at-root .secondary-color-13 & { color: $theme-color-13; }
  @at-root .secondary-color-14 & { color: $theme-color-14; }
}
@mixin theme-secondary-fill {
  @at-root .secondary-color-1 & { fill: $theme-color-1; }
  @at-root .secondary-color-2 & { fill: $theme-color-2; }
  @at-root .secondary-color-3 & { fill: $theme-color-3; }
  @at-root .secondary-color-4 & { fill: $theme-color-4; }
  @at-root .secondary-color-5 & { fill: $theme-color-5; }
  @at-root .secondary-color-6 & { fill: $theme-color-6; }
  @at-root .secondary-color-7 & { fill: $theme-color-7; }
  @at-root .secondary-color-8 & { fill: $theme-color-8; }
  @at-root .secondary-color-9 & { fill: $theme-color-9; }
  @at-root .secondary-color-10 & { fill: $theme-color-10; }
  @at-root .secondary-color-11 & { fill: $theme-color-11; }
  @at-root .secondary-color-12 & { fill: $theme-color-12; }
  @at-root .secondary-color-13 & { fill: $theme-color-13; }
  @at-root .secondary-color-14 & { fill: $theme-color-14; }
}
