html {
  padding-bottom: 0 !important;
  // scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-size: rem-calc(16);
  color: $text-primary;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  background-color: $white;

  * {
    outline: 0;
    box-sizing: border-box;
    text-decoration: none;
  }
}